<template>
  <div class="change-form secure-settings">
    <template v-if="!component">
      <p class="form__title">{{ $t('profile.securitySettings.securitySettings') }}</p>
      <button class="btn__orange btn_change_password" @click="component = 'ChangePasswordForm'">
        {{ $t('profile.securitySettings.changePassword') }}
      </button>
    </template>
    <ChangePasswordForm v-else-if="component === 'ChangePasswordForm'"/>
  </div>
</template>

<script>
import ChangePasswordForm from "@/components/Profile/ChangePasswordForm.vue";

export default {
  components: {ChangePasswordForm},
  name: "SecuritySettings",
  data() {
    return {
      component: null,
    }
  }
}
</script>


<style scoped lang="scss">
.secure-settings {
  .form__title {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
  }
}
</style>
