<template>
  <div class="change-form">
    <p class="form__title">{{ $t('profile.passwordForm.header') }}</p>
    <div style="margin-bottom: 60px;">
      <PasswordInput
          v-model="oldPassword"
          :title="$t('profile.passwordForm.currentPassword.label')"
          :placeholder="$t('profile.passwordForm.currentPassword.placeholder')"
          :validate="$v.oldPassword"
          :error="oldPasswordError"
          :condition="invalidCurrentPassword"
          @blur="$v.oldPassword.$touch()"
      />
    </div>
    <PasswordInput
        v-model="newPassword"
        :title="$t('profile.passwordForm.newPassword.label')"
        :placeholder="$t('profile.passwordForm.newPassword.placeholder')"
        :validate="$v.newPassword"
        :error="newPasswordError"
        @blur="$v.newPassword.$touch()"
    />
    <PasswordInput
        v-model="repeatNewPassword"
        :title="$t('profile.passwordForm.confirmPassword.label')"
        :placeholder="$t('profile.passwordForm.confirmPassword.placeholder')"
        :validate="$v.repeatNewPassword"
        :error="repeatNewPasswordError"
        @blur="$v.repeatNewPassword.$touch()"
    />
    <button class="btn__orange btn_change_password" @click="saveChanges()">
      {{$t('profile.passwordForm.button')}}
    </button>
  </div>
</template>

<script>
import {required, minLength, sameAs} from "vuelidate/lib/validators";
import PasswordInput from "../../components/Ui/PasswordInput";

export default {
  components: {
    PasswordInput,
  },
  computed: {
    oldPasswordError() {
      if (!this.$v.newPassword.required) {
        return this.$i18n.t('profile.passwordForm.currentPassword.required')
      }

      if (this.invalidCurrentPassword) {
        return this.$i18n.t('profile.passwordForm.currentPassword.invalid')
      }

      return ''
    },
    newPasswordError() {
      if (!this.$v.newPassword.required) {
        return this.$i18n.t('profile.passwordForm.newPassword.required')
      }

      if (!this.$v.newPassword.minLength) {
        return this.$i18n.t('profile.passwordForm.newPassword.min')
      }

      return ''
    },
    repeatNewPasswordError() {
      if (!this.$v.repeatNewPassword.required) {
        return this.$i18n.t('profile.passwordForm.confirmPassword.required')
      }

      if (!this.$v.repeatNewPassword.minLength) {
        return this.$i18n.t('profile.passwordForm.confirmPassword.min')
      }

      if (!this.$v.repeatNewPassword.sameAs) {
        return this.$i18n.t('profile.passwordForm.confirmPassword.match')
      }

      return ''
    }
  },
  data() {
    return {
      invalidCurrentPassword: false,
      oldPassword: "",
      newPassword: "",
      repeatNewPassword: "",
    };
  },
  mounted() {
    this.$el.scrollIntoView({ behavior: "smooth", block: "start" })
  },
  validations: {
    oldPassword: {
      required,
    },
    newPassword: {
      required,
      minLength: minLength(6),
    },
    repeatNewPassword: {
      required,
      minLength: minLength(6),
      sameAs: sameAs('newPassword'),
    },
  },
  methods: {
    saveChanges() {
      this.$v.$touch()
      if (this.$v.$error) {
        return;
      }
      this.$store.dispatch('changePassword', {from: this.oldPassword, to: this.newPassword}).then(r => {
        if (r.success) {
          this.$router.push({name: 'profile.password-changed'})
        } else {
          this.invalidCurrentPassword = true;
          this.$v.$reset()
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.form__title {
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}

.change-form {
  padding: 27px 20px;
  background: #2c2c4d;
  border-radius: 6px;
}

.btn_change_password {
  margin-top: 14px;
}

</style>