import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

import Register from './Auth/Register';
import Recover from './Auth/Recover';
import PhoneConfirm from './PhoneConfirm';
import PhoneSet from './PhoneSet';
import TokenStorage from './TokenStorage'
import Verification from './VerificationApi'


export default function Api() {
    const tokenStorage = new TokenStorage();

    let token = tokenStorage.get();
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    const resetToken = () => {
        tokenStorage.reset();
        delete axios.defaults.headers.common['Authorization'];
        token = null;
    }

    const setAuthToken = token => {
        tokenStorage.set(token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    const makeRequest = (url, data) => axios.post(url, data).then(r => r.data)
    this.send = makeRequest


    this.register = new Register(makeRequest, setAuthToken)
    this.recover = new Recover(makeRequest, setAuthToken)
    this.phoneConfirm = new PhoneConfirm(makeRequest)
    this.phoneSet = new PhoneSet(makeRequest)
    this.verification = new Verification(makeRequest)
    this.logout = () => resetToken();
    this.login = (login, password) => makeRequest('auth/login', {phone: login, password}).then(r => {
        if (r.success) {
            setAuthToken(r.token)
        }
        return r.success
    })

    this.loginGoogle = token => makeRequest('auth/login/google', {token}).then(r => {
        if (r.success) {
            setAuthToken(r.token)
        }
        return r.success
    })

    this.loginMailru = (code, redirect) => makeRequest('auth/login/mailru', {code, redirect}).then(r => {
        if (r.success) {
            setAuthToken(r.token)
        }
        return r.success
    })

    this.index = () => makeRequest('index')
    this.games = () => makeRequest('games/list')
    this.gamesVersion = () => makeRequest('games/version')
    this.balance = () => makeRequest('balance')
    this.startGame = (id, return_url, refill_url, language) => makeRequest('games/play', {
        id,
        return_url,
        refill_url,
        language
    })
    this.startDemo = (id, return_url, language) => makeRequest('games/demo', {id, return_url, language})
    this.changePassword = (from, to) => makeRequest('change-password', {from, to})
    this.paymentHistory = (page = 1) => makeRequest('wallet/history', {page})
    this.cashBack = () => makeRequest('wallet/cashBack', {})
    this.deposit = (sum, success_url, failed_url, ps, pending_url) => makeRequest('wallet/deposit-ps', {
        sum,
        success_url,
        failed_url,
        ps,
        pending_url
    })
    this.depositPhone = (ps, phone, sum, success_url, failed_url) => makeRequest('wallet/deposit-phone', {
        ps,
        phone,
        sum,
        success_url,
        failed_url
    })
    this.depositPhoneComplete = ({ps, id, code}) => makeRequest('wallet/deposit-phone-complete', {ps, id, code})
    this.payout = (r) => makeRequest('wallet/payout', r)
    this.setNotificationToken = (token) => makeRequest('set-notification-token', {token})
    this.setOnesignalToken = (token) => makeRequest('set-onesignal-token', {token})
    this.sendPartnerData = (data) => makeRequest('partner-data', {data})
    this.kolesoStatus = () => makeRequest('lottery/status', {})
    this.kolesoSpin = () => makeRequest('lottery/spin', {})

    this.activateBonus = (id) => makeRequest('bonus/activate', {id})

    this.initConfirmation = () => makeRequest('confirmation/init', {});
    this.outConfirmationEnable = () => makeRequest('wallet/payout/confirmation-setup', {disable_confirmation: false})
    this.outConfirmationDisable = ({
                                       process_id,
                                       code
                                   }) => makeRequest('wallet/payout/confirmation-setup', {
        disable_confirmation: true,
        process_id,
        code
    })

    this.tryPromoCode = ({code}) => makeRequest('promo/apply', {code})

    this.requestDepositCryptoWallets = () => makeRequest('wallet/crypto-for-deposit', {})
}
