<template>
  <div class="deposit-form">
    <PromoBanner/>
    <div class="deposit-form__container">
      <div class="deposit__input">
        <p class="title">{{ $t('payIn.sum.label') }}</p>
        <div
            :class="['input__default', { 'in-error': $v.depositValue.$error }]"
        >
          <input
              v-model="depositValue"
              inputmode="numeric"
              @input="customDepositValue = true"
          />
          <p class="currency">{{ currency }}</p>
          <p :class="['error__label', { 'in-error': $v.depositValue.$error }]">
            {{ error }}
          </p>
        </div>
      </div>
      <Hint :on-click="plusDeposit"/>
      <Help style="position: absolute; top: 10px; right: 10px">
        Впишите сумму и выберите метод оплаты.<br/><br/>
        * При оплате балансом мобильного телефона, зачисление денег происходит с задержкой до 1 минуты.<br/><br/>
        ** Для зачисления Каспи перевода, отправьте в чат тех поддержки скрин чека.
      </Help>
    </div>
    <div class="buttons">
      <button v-for="ps in pss" :key="ps.id" class="btn__orange" @click="deposit(ps.id, ps.tag)">
        {{ps.label}}
      </button>
    </div>
    <div v-if="kaspi.enabled" class="kaspi-link-container">
      <router-link :to="{name:'deposit.kaspi'}" :tag="'button'" class="btn__orange kaspi_button">
        {{ $t('payIn.kaspi.link') }}
      </router-link>
    </div>
    <div v-if="depositHint">
      <br/>
      {{ depositHint }}
    </div>
    <PromoCode/>

    <br>
    <br>
    <br>
    <br>
    <br>
    <!--    <br>-->
    <!--    <div class="deposit-form__container">-->
    <!--      <label><input type="checkbox">{{$t('payIn.no_bonus')}}</label>-->
    <!--      <Help>-->
    <!--        За первый депозит вы получите +20% бонусов,-->
    <!--        но вейджер будет больше (x3) обычного (x1),-->
    <!--        и его нельзя будет отыграть в рулетку.-->
    <!--        <br><br>-->
    <!--        Если вы поклонник рулеточных игр &mdash;-->
    <!--        поставьте галочку и откажитесь от бонуса,-->
    <!--        чтобы быстрее получить свой выигрыш.-->
    <!--      </Help>-->
    <!--    </div>-->
  </div>
</template>

<script>
import {required, minValue, integer, maxValue} from "vuelidate/lib/validators";
import Hint from "./Hint";
import Help from "../../views/Wallet/Help";
import PromoCode from "./PromoCode";
import PromoBanner from "../PromoBanner";
import router from "@/router/router";
// import en from "@/localisation/messages/en";

export default {
  components: {PromoBanner, PromoCode, Help, Hint},
  data() {
    return {
      depositValue: this.$route.params.sum || "",
      customDepositValue: false,
      hasPageshowListener: false,
      phone: "",
      showPhoneBlock: false
    };
  },
  validations() {
    return {
      depositValue: {
        required,
        integer,
        minValue: minValue(this.config.min),
        maxValue: maxValue(this.config.max),
      }
    };
  },
  computed: {

    kaspiPhoneToCopy() {
      const phone = this.$store.state.config.deposit.kaspi.phone;
      if (phone.substring(0, 2) === '+7') {
        return phone.substring(2);
      }
      return phone;
    },
    kaspiCardToCopy() {
      return this.$store.state.config.deposit.kaspi.card.replace(/\s/g, '');
    },
    kaspi() {
      return this.$store.state.config.deposit.kaspi;
    },
    currency() {
      return this.$store.state.currency;
    },
    depositHint() {
      return this.$store.state.config.depositHint;
    },
    config() {
      return this.$store.state.config.deposit.hint[this.$store.state.currencyCode] || {min: 1, max: 500000};
    },
    error() {
      const formatter = new Intl.NumberFormat(undefined, {maximumFractionDigits: 0});
      const min = formatter.format(this.config.min);
      const max = formatter.format(this.config.max);
      return `Укажите сумму от ${min} до ${max}`
    },
    pss() {
      const enabled = this.$store.state.config.deposit.enabled;
      const r = [];
      const numbers = {};
      const defaultLabel = this.$i18n.t('payIn.button');
      const tagLabels = {
        phone: this.$i18n.t('payIn.payPhone'),
        crypto: this.$i18n.t('payIn.crypto.enterButton'),
      };

      enabled.forEach(item => {
        const [id, tag] = item.split(':');
        numbers[tag] = 1 + (numbers[tag] || 0);
        const n = numbers[tag];
        const label = (tagLabels[tag] || defaultLabel)
        + (n > 1 ?  ' ' + n : '');
        r.push({id, tag, label});
      })

      return r;
    }
  },
  methods: {
    depositPhone(ps) {
      this.$router.push({name: 'deposit.phone', params: {sum: this.depositValue, ps}});
    },
    depositPhoneRedirect(ps) {
      this.$router.push({name: 'deposit.phone-redirect', params: {sum: this.depositValue, ps}});
    },
    plusDeposit(value) {
      this.depositValue = value;
      this.customDepositValue = false;
    },
    deposit(ps, tag) {

      this.$v.$touch();
      if (this.$v.depositValue.$error) {
        return;
      }

      if(ps === 'paycos1' || ps === 'paycos_gate1_mk') {
        return this.depositPhone(ps);
      }

      if(ps === 'crosspay_phone') {
        return this.depositPhoneRedirect(ps)
      }

      if(tag === 'crypto') {
        this.$router.push({name: 'deposit.crypto', params: {ps}})
        return;
      }

      const data = this.depositData(ps);
      this.$store.dispatch("deposit", data).then((r) => this.handleSubmit(r));
    },
    depositData(ps) {
      const successRoute = this.$router.resolve({name: "deposit.success"});
      const failedRoute = this.$router.resolve({name: "deposit.error"})
      const pendingRoute = this.$router.resolve({name: "deposit.pending"})
      return {
        sum: this.depositValue,
        success_url: new URL(successRoute.href, location.origin).toString(),
        failed_url: new URL(failedRoute.href, location.origin).toString(),
        pending_url: new URL(pendingRoute.href, location.origin).toString(),
        ps
      }
    },
    handleSubmit(r) {
      if (r.ok) {
        if(r.iframe && navigator.userAgent.includes('iPhone')) {
          router.push({name: 'deposit.iframe', params: {url: r.redirect}})
          return;
        }
        this.$store.commit('loading');
        if (!this.hasPageshowListener) {
          const listener = event => {
            if (event.persisted) {
              this.$store.commit('loading_complete');
              window.removeEventListener('pageshow', listener);
              this.hasPageshowListener = false;
            }
          };
          window.addEventListener('pageshow', listener);
          this.hasPageshowListener = true;
        }

        this.$store.dispatch('redirect', r.redirect);
      } else {
        this.$router.push({name: "deposit.error"});
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.kaspi-link-container {
  margin-top: 10px;
  text-align: center;
}

.kaspi_button {
  width: auto;
  padding: 15px 15px 15px 65px;
  background-image: url("./kaspi-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.phone-block {
  margin-top: 16px;
  margin-bottom: 10px;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 300ms;
}

.phone-block.display {
  visibility: visible;
  height: 100%;
  opacity: 1;
}

.deposit-form {
  color: #ffffff;
}

.deposit-form__container {
  padding: 20px;
  background: #2c2c4d;
  border-radius: 6px;
  position: relative;
}

.title {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
}

.currency {
  position: absolute;
  top: 19px;
  right: 11px;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: #ffffff;
}

.buttons {
  transition: all 1s;
  margin-top: 20px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.crypto_container {
  margin-top: 20px;
}

.buttons > button {
  width: unset;
  flex-grow: 1;
  flex-shrink: 1;
}
</style>
