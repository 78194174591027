<template>
  <section class="reg">
    <div class="reg__container">
      <div :class="['reg__main', { 'in-error': regFormError }]">

        <p class="reg__title">{{ $t('registration.init.title') }}</p>
        <!--        <p class="reg__text">-->
        <!--          {{ $t('registration.unconfirmed.text') }}-->
        <!--        </p>-->
        <TelInput
            v-model="phone"
            :type="'text'"
            :title="$t('login.phoneInput.label')"
            :placeholder="$t('registration.init.tel.placeholder')"
            :validate="$v.phone"
            @input="regFormError = false"
            :customStyle="{marginBottom: '25px'}"
        />
        <PasswordInput
            v-model="password"
            :title="$t('registration.unconfirmed.password.label')"
            :error="$t('registration.unconfirmed.password.error')"
            :placeholder="$t('registration.complete.password.placeholder')"
            :validate="$v.password"
            :customStyle="{marginBottom: '20px'}"
        />
        <ReCaptcha :customStyle="{marginBottom: '20px'}" @validate="recaptchaValidate = $event"/>
        <!--        <CheckBoxInput-->
        <!--            v-model="agreement"-->
        <!--            :error="$t('registration.unconfirmed.agreement.error')"-->
        <!--            :message="$t('registration.unconfirmed.agreement.label')"-->
        <!--            @change="agreementChange"-->
        <!--            :customStyle="{marginBottom: '25px'}"-->
        <!--            :validate="$v.agreement"-->
        <!--        />-->
        <button
            class="btn__orange comeIn"
            :disabled="$v.phone.$error || regFormError || !recaptchaValidate"
            @click="regTelEvent()"
        >
          {{ $t('registration.unconfirmed.button') }}
        </button>

        <!--        <SelectBox-->
        <!--            v-model="currency"-->
        <!--            label="Выберите валюту"-->
        <!--            :options="[-->
        <!--            {id:'KZT', label:'Теңге'},-->
        <!--            {id:'KGS', label:'Сом'}-->
        <!--                    ]"/>-->

        <div :class="['reg__message', { 'in-error': regFormError }]">
          <p>{{ $t('registration.init.hasAccount') }}</p>
          <router-link :to="{ name: 'login' }" class="link__orange">
            {{ $t('registration.init.loginLink') }}
          </router-link>
          <div style="margin-top:16px">
            <Social/>
          </div>
        </div>

        <div :class="['reg__error', { 'in-error': regFormError }]">
          <p>{{ $t('registration.init.error.used') }}</p>
          <button class="btn__orange-border" @click="requestReset">{{
              $t('registration.init.error.recoverButton')
            }}
          </button>
          <p style="margin-top: 20px; margin-bottom: 0px;">{{ $t('registration.unconfirmed.loginHint.label') }}</p>
          <router-link :to="{ name: 'login', params: {phone}}" class="link__orange">
            {{ $t('registration.init.loginLink') }}
          </router-link>
        </div>

      </div>

    </div>
  </section>
</template>

<script>
import {required, minLength, sameAs} from "vuelidate/lib/validators";
import TelInput from "../../Ui/TelInput";
import Social from "../Social";
import PasswordInput from "../../Ui/PasswordInput";
import ReCaptcha from "@/components/ReCaptha/ReCaptcha.vue";
// import CheckBoxInput from "../../Ui/CheckBoxInput.vue";

export default {
  // components: {CheckBoxInput, PasswordInput, Social, TelInput},
  components: {ReCaptcha, PasswordInput, Social, TelInput},
  props: {
    page: {
      type: Number,
      default: 0,
      requiered: false,
    },
  },
  data() {
    return {
      // Registration
      phone: "",
      password: "",
      agreement: true,
      regErrorMessage: "",
      regFormError: false,
      currency: "",
      recaptchaValidate: false,
    };
  },
  validations() {
    return {
      phone: {
        required,
        minLength: minLength(this.phoneLen),
      },
      password: {
        required,
        minLength: minLength(6),
      },
      agreement: {
        required,
        sameAs: sameAs(() => true)
      }
    }
  },
  computed: {
    phoneLen() {
      return (this.$store.state.config.phoneMask.match(/#/g) || []).length;
    }
  },
  mounted() {
    // if (this.$i18n.locale === 'kg') {
    //   this.currency = 'KGS';
    // }
    if (this.$store.state.auth) {
      this.$router.push('/')
    }
  },
  methods: {

    requestReset() {
      this.$v.$touch()
      if (this.$v.$error) {
        return
      }

      this.$store.dispatch('recover/init', this.phone)
          .then(r => {
            if (!r.success) {
              this.recoveryFormError = true;
            } else {
              this.$router.push({name: 'recover.confirm'});
            }
          });
    },

    agreementChange(v) {
      this.agreement = v;
    },

    regTelEvent() {
      this.$v.$touch()
      if (this.$v.$error) {
        return
      }

      this.$store.dispatch('register/unconfirmed', {
        phone: this.phone,
        password: this.password,
        currency: this.currency
      }).then(r => {
        if (r.success) {
          this.$router.push({name: 'registration.completed'});
        } else {
          this.regFormError = true;
        }
      })

    },
  },
};
</script>

<style lang="scss" scoped>
.comeIn {
  margin-bottom: 16px;
}

.reg {
  background-color: #1b1c37;
  padding: 0 40px;
  min-height: 503px;
  position: absolute;
  top: 65px;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 1;
  transition: 0.4s;

  .reg__container {
    height: 100%;
    position: relative;

    .reg__main {
      transition: 0.2s;
      transition-delay: 0.2s;

      .reg__title {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
        color: #ffffff;
      }

      .reg__text {
        max-width: 280px;
        margin: 0 auto 15px auto;
        font-weight: normal;
        font-size: 16px;
        line-height: 130.4%;
        text-align: center;
        color: rgba(255, 255, 255, 0.5);
      }

      &.in-error {
        top: 0;
      }
    }

    .reg__error {
      display: none;
      flex-direction: column;
      align-items: center;
      position: relative;
      top: 20px;
      opacity: 0;
      transition: 0.3s;
      transition-delay: 0s;

      p {
        margin-bottom: 15px;
        font-weight: normal;
        font-size: 16px;
        text-align: center;
        line-height: 130.4%;
        color: #ffffff;
      }

      &.in-error {
        display: flex;
        opacity: 1;
        transition-delay: 0.2s;
      }
    }

    .reg__message.in-error {
      display: none;
    }

    .reg__message {
      margin-top: 25px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .comeIn {
        margin-bottom: 16px;
      }

      p {
        font-weight: normal;
        font-size: 16px;
        line-height: 130.4%;
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  &.active-main {
    right: -100%;
    left: 100%;
    opacity: 0;
  }

  &.active-next {
    right: -100%;
    left: 100%;
    opacity: 0;
  }
}
</style>
