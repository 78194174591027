<template>
  <div class="confirm-email-form">
    <template>
      <Input
          v-model="email"
          type="email"
          :title="confirmStatus === 'success' ? $t('profile.confirmEmail.yourConfirmedEmail') : $t('profile.setEmail.label')"
          :validate="$v.email"
          :custom-error="emailInUse ? $t('profile.setEmail.used') : ''"
          :condition="confirmStatus"
          :readonly="['pending', 'success'].includes(confirmStatus)"
          :canEdit="['pending', 'success'].includes(confirmStatus)"
          :timer="confirmStatus === 'pending' ? timer : 0"
          @timerEnd="timer = 0; confirmStatus = 'wait'"
          @changeBtnClick="timer = 0; confirmStatus = 'wait'"
      />
    </template>

    <template v-if="confirmStatus === 'wait'">
      <button class="btn__orange btn__confirm" @click="initSetEmail">{{ $t('profile.confirmEmail.button') }}</button>
    </template>

    <div class="email-verification-desc" v-if="confirmStatus === 'pending'">
      <div>{{ $t('profile.confirmEmail.descPart1') }}</div>
      <div>{{ $t('profile.confirmEmail.descPart2') }}</div>
      <div>{{ $t('profile.confirmEmail.descPart3') }}</div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/Ui/Input.vue";
import {email, required} from "vuelidate/lib/validators";

const timer = 10;

export default {
  name: "ConfirmEmailForm",
  components: {
    Input,
  },
  data() {
    return {
      email: "",
      emailInUse: false,
      confirmStatus: 'wait',
      timer: timer,
    };
  },
  validations() {
    return {
      email: {
        required,
        email
      }
    }
  },
  mounted() {
    window.scrollTo({top: 0})
  },
  methods: {
    initSetEmail() {
      this.emailInUse = false

      this.$v.$touch()
      if (this.$v.$error) {
        return
      }

      // TODO УБрать, когда будет подключено реальное api
      const fakeResponse = 'success';

      switch (fakeResponse) {
        case 'success':
          // this.confirmStatus = fakeResponse;
          break;
        case 'wait':
          this.$router.push({name: 'wait', params: {waitSeconds: '5', message: 'Повторная отправка Email доступна'}})
          break;
        case 'email_in_use':
          this.emailInUse = true;
          break;
      }

      this.confirmStatus = 'pending';
      this.timer = timer;

      // TODO для подтвержденного состояния надо сделать
      // this.confirmStatus = 'success';

      // TODO END УБрать, когда будет подключено реальное api

      // TODO указать правильный маршрут. Указал по аналогии с проверкой телефона
      // this.$store.dispatch('emailSet/init', {email: this.email}).then(r => {
      //     if (r.success) {
      //         // this.$router.push({name: 'profile.set-phone.confirm'});
      //     }
      //     if (r.code === 'wait') {
      //         this.$router.push({name: 'wait', params: {waitSeconds: r.wait_seconds}})
      //     }
      //     if (r.code === 'phone_in_use') {
      //         this.emailInUse = true;
      //     }
      //     if (r.code === 'player_already_has_email') {
      //         this.$router.push({name: 'profile'});
      //     }
      // })
    },
  },
};
</script>

<style lang="scss" scoped>

.confirm-email-form {
  margin-top: 40px;

  .email-verification-desc {
    color: #9393AA;
    text-align: center;
    margin: 10px auto 0 auto;
  }
}

.form__title {
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}

.change-form {
  padding: 27px 20px;
  background: #2c2c4d;
  border-radius: 6px;
}

.btn__confirm {
  margin-top: 0px;
  padding: 15px;
}

.input__container {
  position: relative;

  .addon {
    position: absolute;
    top: 17px;
    right: 12px;
  }
}

label {
  font-weight: normal;
  font-size: 16px;
  line-height: 14px;
  color: #ffffff;
  margin-bottom: 6px;
  display: block;
}
</style>
