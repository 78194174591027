<template>
  <Error
      :title="$t('profile.waitComponent.needWait')"
      :message="fullMessage"
      :button-callback="() => this.$router.back()"
      :button-text="$t('profile.waitComponent.back')"
  />
</template>

<script>
import Error from "../Ui/Error.vue";

export default {
  name: "Wait",
  components: {
    Error
  },
  props: {
    message: {
      type: String,
    },
    waitSeconds: {
      type: Number,
      required: true
    }
  },
  computed: {
    fullMessage() {
      const left = Math.ceil(this.waitSeconds / 60);

      return (this.message ? this.message : this.$t('profile.waitComponent.defaultMsg')) + ' через ' + left + ' ' + plural([this.$t('profile.waitComponent.minute'), this.$t('profile.waitComponent.minutes'), this.$t('profile.waitComponent.minutes2')], left);
    }
  }
};

/**
 * @param {Array<String>} forms
 * @param {Number} n
 * @returns {String}
 */
function plural(forms, n) {
  let idx;
  // @see http://docs.translatehouse.org/projects/localization-guide/en/latest/l10n/pluralforms.html
  if (n % 10 === 1 && n % 100 !== 11) {
    idx = 0; // one
  } else if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
    idx = 1; // few
  } else {
    idx = 2; // many
  }
  return forms[idx] || '';
}
</script>
