<template>
  <div class="confirm-verification-form">
    <div class="input__default" style="margin-bottom: 0">
      <div class="input__container">
        <Input
            v-model="form.secondName"
            :title="$t('profile.verificationForm.secondName')"
            :validate="$v.form.secondName"
            :readonly="form.confirmedSecondName"
            @change="$store.dispatch('verification/saveInput', {key: 'secondName', value: $event})"
        />
      </div>
    </div>
    <div class="input__default" style="margin-bottom: 0">
      <div class="input__container">
        <Input
            v-model="form.firstName"
            :title="$t('profile.verificationForm.firstName')"
            :validate="$v.form.firstName"
            :readonly="form.confirmedName"
            @change="$store.dispatch('verification/saveInput', {key: 'firstName', value: $event})"
        />
      </div>
    </div>
    <div class="input__default" style="margin-bottom: 0">
      <div class="input__container">
        <Input
            type="date"
            v-model="form.date"
            :title="$t('profile.verificationForm.dateOfBirth')"
            :validate="$v.form.date"
            :readonly="form.confirmedDate"
            @change="$store.dispatch('verification/saveInput', {key: 'date', value: $event})"
        />
      </div>
    </div>

    <template v-if="!form.firstPage">
      <label class="btn__orange btn__confirm" @click="onInitLoadDocument"
             for="firstPageInput">{{ $t('profile.verificationForm.uploadPhoto') }}</label>
      <input id="firstPageInput" type="file" style="display: none" accept="image/*"
             @change="form.firstPage = $event.target.files[0]">
      <div class="verification-desc">{{ $t('profile.verificationForm.document1') }}</div>
    </template>


    <div class="document-pages" v-else>
      <div class="document-pages-item">
        <div class="document-pages-item-loaded">{{ $t('profile.verificationForm.side1') }}
          <unicon name="check" fill="#32cd32" class="addon"/>
        </div>
      </div>
      <div class="document-pages-item">
        <template v-if="!form.secondPage">
          <label for="secondPageInput" class="btn__orange btn__confirm"
                 @click="onInitLoadDocument">{{ $t('profile.verificationForm.uploadSide2Photo') }}</label>
          <input id="secondPageInput" type="file" style="display: none" accept="image/*" @change="onFileSelected">
        </template>

        <div class="document-pages-item-loaded" v-else>{{ $t('profile.verificationForm.side2') }}
          <unicon name="check" fill="#32cd32" class="addon"/>
        </div>
      </div>
    </div>
    <div class="verification-desc" v-if="form.firstPage && !form.secondPage">
      {{ $t('profile.verificationForm.document2') }}
    </div>
    <div class="verification-desc" v-if="form.firstPage && form.secondPage">
      {{ $t('profile.verificationForm.documentLoaded') }}
    </div>
  </div>
</template>

<script>
import Input from "@/components/Ui/Input.vue";
import {required} from "vuelidate/lib/validators";

export default {
  name: "ConfirmVerificationForm",
  components: {
    Input,
  },
  data() {
    return {
      form: {
        secondName: "",
        firstName: "",
        date: "",
        firstPage: null,
        secondPage: null,
        emailInUse: false,
        confirmedSecondName: false,
        confirmedName: false,
        confirmedDate: false,
      }
    };
  },
  validations() {
    return {
      form: {
        secondName: {
          required,
        },
        firstName: {
          required,
        },
        date: {
          required,
        }
      }
    }
  },
  mounted() {
    this.$el.scrollIntoView({ behavior: "smooth", block: "start" })
  },
  methods: {
    onFileSelected(event) {
      this.$v.form.$touch();

      if (this.$v.form.$error) {
        event.preventDefault();
        return false;
      }

      const elId = event.target.id;
      const formData = new FormData();

      switch (elId) {
        case 'firstPageInput' :
          this.form.firstPage = event.target.files[0];
          formData.append('firstPage', event.target.files[0])
          break;
        case 'secondPageInput' :
          this.form.secondPage = event.target.files[0]
          formData.append('secondPage', event.target.files[0])

          setTimeout(() => {
            this.$store.commit('verification/setConfirmStatus', 'pending');
          }, 3000);
          break;
      }

      event.target.value = '';

      this.$store.dispatch('verification/saveFile', formData)

    },
    onInitLoadDocument(event) {
      this.$v.form.$touch();

      if (this.$v.form.$error) {
        event.preventDefault();
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.confirm-verification-form {
  margin-top: 40px;

  .document-pages {
    display: flex;
    justify-content: space-between;

    &-item {
      width: calc(50% - 15px);

      label {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        height: 45px;
      }

      &-loaded {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 45px;
        color: white;
        background: #4A4A69;
        border-radius: 6px;
        font-size: 13px;


      }
    }
  }

  .verification-desc {
    color: #9393AA;
    text-align: center;
    max-width: 300px;
    margin: 10px auto 0 auto;
  }
}

.form__title {
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}

.change-form {
  padding: 27px 20px;
  background: #2c2c4d;
  border-radius: 6px;
}

.btn__confirm {
  margin-top: 0px;
  padding: 15px;
}

.input__container {
  position: relative;

  .addon {
    position: absolute;
    top: 17px;
    right: 12px;
  }
}

label {
  font-weight: normal;
  font-size: 16px;
  line-height: 14px;
  color: #ffffff;
  margin-bottom: 6px;
  display: block;

  &.btn__confirm {
    text-align: center;
    max-width: 100%;
    box-sizing: border-box;
  }
}


</style>
