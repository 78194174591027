export default api => ({
    namespaced: true,
    state: {
        form: {
            firstName: '',
            secondName: '',
            date: ''
        },
        verificationStatus: 'wait', //pending, success, reject
    },
    getters: {
        getVerificationStatus(state) {
            return state.verificationStatus;
        },
        getForm(state) {
            return state.form;
        }
    },
    mutations: {
        saveInput(state, {key, value}) {
            state.form[key] = value;
        },
        setConfirmStatus(state, status) {
            state.verificationStatus = status;
        }
    },
    actions: {
        saveInput({commit}, {key, value}) {
            commit('saveInput', {key, value})
            // return api.saveInput(key, value)
            //     .then(r => {
            //         console.log('re', r)
            //         if (r.success) {
            //             console.log('re2', r)
            //             commit('saveInput', {key, value})
            //         }
            //         return r;
            //     })
        },
        saveFile({commit}, formData) {
            return api.saveFile(formData)
                .then(r => {
                    if (r.success) {
                        commit(); // TODO удалить. не уверен, что надо что-то делать после отправки файла.
                    }
                    return r;
                })
        },
    }

})
