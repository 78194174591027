export default (router, store) => router.beforeEach((to, from, next) => {
    if (
        to.matched.some(record => record.meta.requiresAuth) &&
        store.state.auth === false
    ) {
        next({name: 'login', params: {return: to.fullPath}});
        return
    }

    next()
})
