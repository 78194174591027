<template>
  <div class="change-form verification-settings">
    <template v-if="!component">
      <p class="form__title">{{ $t('profile.verificationForm.verification') }}</p>
      <Alert :message="$t('profile.verificationForm.reject')" v-if="verificationStatus === 'reject'"></Alert>
      <button class="btn__orange btn_change_password" @click="component = 'confirmVerificationForm'">
        {{ $t('profile.verificationForm.uploadDocuments') }}
      </button>
    </template>
    <template v-else-if="component === 'confirmVerificationForm'">
      <p class="form__title">{{ $t('profile.verificationForm.uploadDocuments') }}</p>
      <ConfirmVerificationForm/>
    </template>
  </div>

</template>

<script>
import ConfirmVerificationForm from "@/components/Profile/ConfirmVerificationForm.vue";
import Alert from "@/components/Ui/Alert.vue";

export default {
  components: {ConfirmVerificationForm, Alert},
  name: "VerificationSettings",
  data() {
    return {
      component: null,
    }
  },
  computed: {
    verificationStatus() {
      return this.$store.getters["verification/getVerificationStatus"];
    }
  }
}
</script>


<style scoped lang="scss">
.verification-settings {
  .form__title {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
  }
}
</style>
