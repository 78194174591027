<template>
  <div class="confirm-verification-form">
    <Input
        :value="verificationData.secondName"
        :title="$t('profile.verificationForm.secondName')"
        :condition="verificationStatus"
        :readonly="true"
    />
    <Input
        :value="verificationData.firstName"
        :title="$t('profile.verificationForm.firstName')"
        :condition="verificationStatus"
        :readonly="true"
    />
    <Input
        style="margin-bottom: 0;"
        type="date"
        :value="verificationData.date"
        :title="$t('profile.verificationForm.dateOfBirth')"
        :condition="verificationStatus"
        :readonly="true"
    />
  </div>
</template>

<script>
import Input from "@/components/Ui/Input.vue";

export default {
  name: "VerificationData",
  components: {
    Input,
  },
  mounted() {
    //TODO убрать таймер, он нужен, чтобы показать, что будет, когда будет апрув
    setTimeout(() => {
      this.$store.commit('verification/setConfirmStatus', 'success')
    }, 5000);
  },
  computed: {
    verificationData() {
      return this.$store.getters["verification/getForm"];
    },
    verificationStatus() {
      return this.$store.getters["verification/getVerificationStatus"];
    }
  },
};
</script>

<style lang="scss" scoped>

.confirm-verification-form {
  margin-top: 40px;
}

.form__title {
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}

.change-form {
  padding: 27px 20px;
  background: #2c2c4d;
  border-radius: 6px;
}

.btn__confirm {
  margin-top: 0px;
  padding: 15px;
}

.input__container {
  position: relative;

  .addon {
    position: absolute;
    top: 17px;
    right: 12px;
  }
}

label {
  font-weight: normal;
  font-size: 16px;
  line-height: 14px;
  color: #ffffff;
  margin-bottom: 6px;
  display: block;

  &.btn__confirm {
    text-align: center;
    max-width: 100%;
    box-sizing: border-box;
  }
}


</style>
