<template>
  <div :style="customStyle">
    <div class="g-recaptcha" id="g-recaptcha" style="display: flex; justify-content: center"></div>
  </div>
</template>

<script>

export default {
  name: "ReCaptcha",
  props: {
    customStyle: {
      type: Object,
    }
  },
  mounted() {
    this.$loadScript("https://www.google.com/recaptcha/api.js?render=explicit")
        .then(() => {
          const interval = setInterval(() => {
            if (window.grecaptcha.render) {
              clearInterval(interval);
              window.grecaptcha.render(
                  'g-recaptcha',
                  {
                    sitekey: process.env.VUE_APP_GRECAPTCHA_SITE_KEY,
                    callback: (response) => {
                      this.$emit('validate', !!response)
                    },
                  }
              )
            }
          }, 10)
        });
  },
}
</script>


<style scoped lang="scss">
.g-recaptcha {
  //transform:scale(0.9);
  //transform-origin:0 0;
}
</style>