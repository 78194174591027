<template>
  <section class="persons">
    <div class="persons__container">
      <p class="persons__title">{{$t('index.winnersHeader')}}</p>
      <ul class="persons__list">
        <li v-for="(w, n, i) in winners" :key="i">
          <img :src="w.image" @click="play(w.game_id)"/>
          <p class="person__code">{{w.tel}}</p>
          <p class="person__jackpot">{{w.sum}} {{currency}}</p>
        </li>
      </ul>
    </div>
  </section>

</template>

<script>


export default {
  name: "Winners",
  methods: {
    play(id) {
      this.$router.push({name: 'play', params: {id}})
    }
  },
  computed: {
    winners() {
      const list = [];

      this.$store.state.winners.forEach(w => {
          list.push({image: w.game_image, ...w})
      })

      return list;
    },
    currency() {
      return this.$store.state.currency;
    }
  }
}
</script>

<style lang="scss" scoped>

.persons {
  margin: 24px 0 20px 0;

  .persons__container {
    .persons__title {
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #ffffff;
    }

    .persons__list {
      display: flex;
      flex-wrap: wrap;

      li {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        img {
          width: 42px;
          margin-right: 10px;
          background: linear-gradient(
                  140.1deg,
                  #242732 -2.93%,
                  #31343e 52.41%,
                  #245650 107.75%
          );
          box-shadow: 0px 6px 12px -10px rgba(245, 45, 45, 0.55),
          inset 0px 1px 1px #808080;
          border-radius: 6px;
        }

        .person__code {
          font-weight: 600;
          font-size: 16px;
          line-height: 140.7%;
          color: #fefb07;
        }

        .person__jackpot {
          margin-left: auto;
          font-weight: bold;
          font-size: 16px;
          line-height: 140.7%;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
