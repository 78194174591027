export default function () { // TODO <-- На вход подать makeRequest, а то сборщик ругается, что не используется при фейк запросах
    //TODO сделать апи
    //this.saveInput = (key, value) => makeRequest('/todo', {key: value}); // TODO когда будет реальный запрос
    this.saveInput = (key, value) => { // TODO Удалить этот фейковый
        console.log(key)
        console.log(value)

        return new Promise(function (resolve) {
            setTimeout(function () {
                resolve({
                    success: true
                });
            }, 100);
        });
    };
    this.saveFile = (formData) => { // TODO Для зайгрузки файлов нужен FormData + Mulpipart/FormData. перепсиать на нормальный метод
        console.log(formData)

        return new Promise(function (resolve) {
            setTimeout(function () {
                resolve({
                    success: true
                });
            }, 100);
        });
    };
}
