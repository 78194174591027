<template>
  <div :class="[ 'input__default', { 'in-error': validate.$error || customError } ]" :style="customStyle">
    <label>{{ title }}</label>
    <div class="input__container">
      <div class="input-head-actions" v-if="canEdit" @click="onChangeBtnClick">
        <div style="margin-right: 5px;">{{ $t('profile.inputComponent.changeBtn') }}</div>
        <unicon name="pen" width="14px" fill="#9393AA" class="addon"/>
      </div>
      <input
          :type="type"
          :placeholder="placeholder"
          :value="value"
          :readonly="readonly"
          @input="onInput"
          @change="onChange"
      />
      <div class="input-footer-actions" v-if="timer">
        {{ secondsToTime }}
      </div>
      <unicon v-if="condition === 'success'" name="check" fill="#32cd32" class="addon"/>
      <unicon v-else-if="condition === 'pending'" name="clock-three" fill="#9393AA" class="addon"/>
      <unicon v-else-if="condition === 'reject'" name="times" fill="#FE5C07" class="addon"/>
      <unicon v-else-if="condition === 'edit'" name="pen" fill="#FFFFFF" class="addon"/>
    </div>

    <p :class="['error__label', { 'in-error': validate.$error}]">
      {{ error }}
    </p>

    <p :class="['error__label', { 'in-error': !!customError && !validate.$error }]">
      {{ customError }}
    </p>

  </div>
</template>

<script>
import {clear} from "core-js/internals/task";

export default {
  props: {
    customStyle: {
      type: Object,
      default: () => {
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    customError: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    title: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    condition: {
      type: String,
      default: 'wait'
    },
    validate: {
      type: Object,
      default: () => ({
        $error: false,
        $invalid: false,
      }),
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    timer: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      timerLeft: 0,
      timerInterval: null,
    }
  },
  computed: {
    secondsToTime() {
      if (!this.timerLeft) return '';
      const date = new Date(this.timerLeft * 1000);
      const minutes = date.getUTCMinutes();
      const seconds = date.getSeconds();

      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
  },
  mounted() {
    if (this.timer) {
      this.runTimer(this.timer);
    }
  },
  methods: {
    onChangeBtnClick() {
      this.timerInterval && clearInterval(this.timerInterval);
      this.$emit('changeBtnClick');
    },
    clear,
    runTimer(timer) {
      if (!timer) return false;
      this.timerLeft = timer;
      this.timerInterval = setInterval(() => {
        this.timerLeft--;

        if (!this.timerLeft) {
          clearInterval(this.timerInterval);
          this.$emit('timerEnd')
        }

      }, 1000);
    },
    onInput(event) {
      this.$emit("input", event.target.value);
    },
    onChange(event) {
      this.$emit("change", event.target.value);
    },
  },
  watch: {
    timer(timer) {
      this.runTimer(timer);
    }
  }
};
</script>

<style lang="scss" scoped>
.input__default {
  &.confirmed {
    margin-bottom: 0;
  }

  .input__container {
    position: relative;

    .addon {
      position: absolute;
      top: 17px;
      right: 12px;
    }

    .input-head-actions,
    .input-footer-actions {
      position: absolute;
      right: 0;
      color: #9393AA;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      font-weight: 500;

      .addon {
        position: relative;
        top: unset;
        right: unset;
      }
    }

    .input-head-actions {
      top: -20px;
    }

    .input-footer-actions {
      bottom: -20px;
    }
  }

  .error__label {
    display: none;
  }

  &.in-error {
    .error__label {
      display: block;
    }
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
}
</style>
