<template>
  <div>
    <div class="profile__container">
      <ProfileSettings/>
      <br>
      <VerificationSettings v-if="['wait', 'reject'].includes(verificationStatus)"/>
      <br>
      <SecuritySettings/>
    </div>
  </div>
</template>

<script>
import ProfileSettings from "./ProfileSettings.vue";
import SecuritySettings from "@/components/Profile/SecuritySettings.vue";
import VerificationSettings from "@/components/Profile/VerificationSettings.vue";

export default {
  name: "Form",
  components: {
    ProfileSettings,
    VerificationSettings,
    SecuritySettings,
  },
  mounted() {
    window.scrollTo({top: 0})
  },
  computed: {
    verificationStatus() {
      return this.$store.getters["verification/getVerificationStatus"];
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.profile__container {
  padding: 10px 20px 20px 20px;
}

.profile__title {
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
}

.change-form {
  padding: 27px 20px;
  background: #2c2c4d;
  border-radius: 6px;
}

.form__title {
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}

.change-form {
  padding: 27px 20px;
  background: #2c2c4d;
  border-radius: 6px;
}

.btn_change_password {
  margin-top: 14px;
}

.btn__confirm {
  margin-top: 0px;
  padding: 15px;
}

.input__container {
  position: relative;

  .addon {
    position: absolute;
    top: 17px;
    right: 12px;
  }
}
</style>
