<template>
  <div class="home__page">
    <DepositBanner/>
    <Banners/>
    <Games :games="games"/>
    <Winners v-if="displayWinners"/>
  </div>
</template>

<script>
import Banners from "../components/Home/Banners";
import Games from "../components/Home/Games/Games";
import Winners from "../components/Home/Winners";
import DepositBanner from "../components/Home/DepositBanner";

export default {
  components: {
    DepositBanner,
    Winners,
    Games,
    Banners
  },
  computed: {
    games() {
      return this.$store.state.games;
    },
    displayWinners() {
      return this.$store.state.displayWinners
    },
  },
  beforeMount() {
    this.$store.dispatch('index');
  },
};
</script>

<style lang="scss" scoped>
.home__page {
  padding: 0 20px 20px;
}
</style>
<style>
.wheel-container {
  z-index: 2;
}
</style>
