<template>
  <div class="change-form secure-settings">
    <p class="form__title">{{ confirmedPhone ? $t('profile.header2') : $t('profile.confirmPhone.header') }}</p>

    <ConfirmPhoneForm/>
    <ConfirmEmailForm v-if="confirmedPhone"/>

    <VerificationData v-if="['pending', 'success'].includes(verificationStatus)"/>

  </div>

</template>

<script>
// import ChangePasswordForm from "@/components/Profile/ChangePasswordForm.vue";
import ConfirmPhoneForm from "@/components/Profile/ConfirmPhoneForm.vue";
import ConfirmEmailForm from "@/components/Profile/ConfirmEmailForm.vue";
import VerificationData from "@/components/Profile/VerificationData.vue";

export default {
  components: {
    VerificationData,
    ConfirmPhoneForm,
    ConfirmEmailForm
  },
  name: "ProfileSettings",
  data() {
    return {
      component: null,
    }
  },
  computed: {
    verificationStatus() {
      return this.$store.getters["verification/getVerificationStatus"];
    },
    confirmedPhone() {
      return this.$store.state.identityConfirmed && this.$store.state.hasPhone
          ? this.$store.state.phone
          : ''
    },
  }
}
</script>


<style scoped lang="scss">
.secure-settings {
  .form__title {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
  }
}
</style>
