<template>
  <div class="alert">
    {{ message }}
    <unicon v-if="state === 'error'" name="times" fill="#f45a0e" class="addon"/>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    message: {
      required: true,
      type: String,
    },
    state: {
      type: String,
      default: 'error'
    }
  }
}
</script>


<style scoped lang="scss">
.alert {
  width: 100%;
  min-height: 43px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  background: #4A4A69;
  border-radius: 6px;
  font-size: 16px;
  padding: 12px 16px;
  box-sizing: border-box;
  margin-bottom: 24px;
  font-weight: 500;

  .unicon {
    display: flex;
  }
}
</style>
